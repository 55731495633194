
import Vue from "vue";

export default Vue.extend({
  name: "ConfirmDialog",
  props: {
    width: Number,
    confirmBtnText: String,
    cancelBtnText: String,
  },
  data: () => ({
    visible: false,
    title: "title",
    body: "body",
  }),
  methods: {
    open(slots?: { title?: string; body?: string }): void {
      this.visible = true;
      this.title = slots?.title || "title";
      this.body = slots?.body || "body";
    },
    close(event: string): void {
      this.visible = false;
      this.$emit(event);
    },
  },
});
